<template>
  <section id="apartmans" class="grey lighten-3 px-3">
    <section-header
      sub-header="Apartmanok"
      text="Kiadó szállások Törökszentmiklóson családoknak, baráti társaságoknak, brigádoknak, munkás csoportoknak, a város több különálló apartmanjaiban."
    />

    <v-container
      :mt-5="$vuetify.breakpoint.mdAndUp"
      :pt-5="$vuetify.breakpoint.mdAndUp"
      px-0
      pb-0
    >
      <v-row
        v-for="(feature, i) in features"
        :key="i"
        :class="[$vuetify.breakpoint.mdAndUp && 'my-5']"
        align="center"
        :style="{
          flexDirection:
            $vuetify.breakpoint.mdAndUp && i === 1 ? 'row-reverse' : 'row',
        }"
      >
        <!--  <v-col class="hidden-sm-and-down"></v-col> -->
        <v-col cols="12" md="6">
          <v-sheet max-width="400" class="mx-auto">
            <div>
              <v-carousel v-if="i == 0" hide-delimiters>
                <v-carousel-item
                  v-for="(item, i) in gallery_0"
                  :key="i"
                  :src="item.src"
                ></v-carousel-item>
              </v-carousel>
              <v-carousel v-if="i == 1" hide-delimiters>
                <v-carousel-item
                  v-for="(item, i) in gallery_1"
                  :key="i"
                  :src="item.src"
                ></v-carousel-item>
              </v-carousel>
              <v-carousel v-if="i == 2" hide-delimiters>
                <v-carousel-item
                  v-for="(item, i) in gallery_2"
                  :key="i"
                  :src="item.src"
                ></v-carousel-item>
              </v-carousel>
            </div>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="6">
          <h3
            class="headline font-weight-regular mb-3 mt-2"
            v-text="feature.header"
          />
          <p class="body-1 font-weight-light" v-text="feature.text" />
        </v-col>
        <v-col
          v-if="i !== features.length - 1"
          cols="12"
          class="py-5"
          order-xs="4"
        >
          <v-divider />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data: () => ({
    features: [
      {
        header: "A apartman",
        text: "Törökszentmiklós központjához közel helyezkedik el. Férőhelyek száma 15fő. Az emeleten 9 ágy a földszinten 6 ágy. Szintenként külön fürdők és felszerelt konyhák. Kábel tv minden szobában, internet =korlátlan wifi. Mosási lehetőség. Parkolás az udvarban. 100m-en belül dohánybolt, kávézó, cukrászda, élelmiszerboltok. A szintek külön is bérelhetők, mert külön bejárattal rendelkezik.",
      },
      {
        header: "B apartman",
        text: "Törökszentmiklós központjában található. Vasút és buszállomás, dohánybolt, élelmiszerbolt közelségében. Férőhelyek száma 9fő. Minden szobában mindigtv, wifi lehetőség. Felszerelt konyha, tusoló, külön 2wc. Nagy udvarban parkolási lehetőség, szerszámok tárolása. Igény szerinti mosási lehetőség",
      },
      {
        header: "C apartman",
        text: "Törökszentmiklós központjában  igényes kis lakás kiadó 1-5 főig. Tusoló, külön WC, felszerelt konyha,  kábel tv, wifi. Közelben vasút-buszállomás, dohánybolt, élelmiszerbolt. Gépkocsi parkolása itt is megoldott. ",
      },
    ],
    gallery_0: [
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman1%2FIMG-2140f340fed44bd6183cf1762cb09703-V.jpg?alt=media&token=c2f445c1-e0ee-4c06-897f-ca66c8dc6410",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman1%2FIMG-43e1ac5bd2a35e95136d8875b1052e51-V.jpg?alt=media&token=fe987a48-9859-416a-8c80-08e18f52c7c5",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman1%2FIMG-48821d3a13e0a0a358542ea77ecb6ae2-V.jpg?alt=media&token=8fd91921-8fc0-461b-bd64-df14deb9c890",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman1%2FIMG-69c5ffc7b3de89348acd639568ba0e76-V.jpg?alt=media&token=c51e1fbd-b151-4cee-a5ff-75868883fd05",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman1%2FIMG-7544d8d39ef7327a3fcd13bc347319c2-V.jpg?alt=media&token=06459ef6-ca98-43ef-9415-0151bada9b0b",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman1%2FIMG-819a71d5cde2ab729e352d4cbc11d592-V.jpg?alt=media&token=4ddce119-7f6e-4558-9e16-4a0aa808d37c",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman1%2FIMG-823c03cebffd7646b752f24935326867-V.jpg?alt=media&token=abb05bc0-3b81-42d3-9762-0ef2c9514962",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman1%2FIMG-86a1da3e63b558d91960b500fe5cebe5-V.jpg?alt=media&token=b2a6dbbc-07c9-4614-a38f-f2051d032c42",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman1%2FIMG-8e98149738ca0d55a1960127fbc83a0a-V.jpg?alt=media&token=cd6016f6-10b7-403b-81c0-fa3ad911851f",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman1%2FIMG-ad347d0d249b4085adf190ac483e3145-V.jpg?alt=media&token=4a9907b0-5b23-4e82-b37f-e3aad139c719",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman1%2FIMG-c2fae979da37f4857beb48f2254ded59-V.jpg?alt=media&token=d61b4ff3-73c5-4990-8475-4f8e06638598",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman1%2FIMG-ce8ff12f8be4270488e118ee2c071859-V.jpg?alt=media&token=a1a76ddb-92f7-46ab-927c-17cf33b4654e",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman1%2FIMG-d45115a824852b0370bba2ee5632ef77-V.jpg?alt=media&token=5c7ab0b5-df24-4f2a-beee-6c4bbf01dd8b",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman1%2FIMG_20191001_103007.jpg?alt=media&token=b3ed0d3f-c139-42e1-9d29-bf5edfbc789d",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman1%2FIMG_20191001_103017.jpg?alt=media&token=2bee4f63-3439-464c-b6d1-4f1d6b892cbf",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman1%2FIMG_20191001_103116.jpg?alt=media&token=9c0e73b5-cfd0-483c-bae1-e2e89e58b9c6",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman1%2FIMG_20191001_103132.jpg?alt=media&token=071ba4ec-b0ba-40e5-a4be-7cfcfadccae1",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman1%2FIMG_20191001_103140.jpg?alt=media&token=cdfca99a-4995-4331-b74e-5f16dd9082ce",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman1%2FIMG_20191001_103151.jpg?alt=media&token=48746094-0136-45cf-8cdb-08d9f0993eb9",
      },
    ],
    gallery_1: [
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman2%2FIMG_20210726_153709.jpg?alt=media&token=5ab19c80-93bf-4f83-a984-716a7d21aa2c",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman2%2FIMG_20210726_153728.jpg?alt=media&token=03b68e02-14c0-4b42-8d93-c12f0e671b1c",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman2%2FIMG_20210726_153740.jpg?alt=media&token=2da76d66-7919-4b22-abb8-ba917b7d09d3",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman2%2FIMG_20210726_153758.jpg?alt=media&token=af844fc2-adfd-49de-8dbc-323622e36508",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman2%2FIMG_20210726_153812.jpg?alt=media&token=05213982-4baa-4afa-a204-bf6aa6e8d705",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman2%2FIMG_20210726_153825.jpg?alt=media&token=07a52860-5f7f-4a88-8b24-bac1f9a35e97",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman2%2FIMG_20210726_153835.jpg?alt=media&token=939f5a04-61d4-49f4-94bd-3615725c3363",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman2%2FIMG_20210726_153842.jpg?alt=media&token=299e925b-c6c7-4016-a341-a4db822f0c7e",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman2%2FIMG_20210726_153853.jpg?alt=media&token=fc8126f9-85a8-46d0-988b-b1d5923037c2",
      },
    ],
    gallery_2: [
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman3%2FIMG_20210824_145840.jpg?alt=media&token=3d12b92d-2d29-4f02-a407-24d839c0759a",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman3%2FIMG_20210824_145906.jpg?alt=media&token=e484115f-1f6f-4160-8873-cac42f32da66",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman3%2FIMG_20210824_145911.jpg?alt=media&token=7890b998-02ad-43fd-ae1c-bc183e90a96f",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman3%2FIMG_20210824_145926.jpg?alt=media&token=22a92b30-3af7-4533-a632-a495766ff401",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman3%2FIMG_20210824_145957.jpg?alt=media&token=e7ea6d6c-af68-4627-b620-12b3816f8e31",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman3%2FIMG_20210824_150023.jpg?alt=media&token=09f5984c-6455-412d-81c0-0359e395d4fe",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman3%2FIMG_20210824_150058.jpg?alt=media&token=bf0ea454-d075-4f60-94b6-a76721b3234f",
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/torokszentmiklosszallas.appspot.com/o/apartman3%2FIMG_20210824_150140.jpg?alt=media&token=838306eb-9206-4b19-abcb-69908987d84c",
      },
    ],
  }),
};
</script>
